export namespace ReportConst {
  export enum Report {
    MostPopularJig = 'MostPopularJigs',
    JigViewActivity = 'JigViewActivity',
    AppActivities = 'AppActivities',
    TenantDashboard = 'TenantDashboard',
  }

  export enum ReportActivity {
    ViewingJig = 'ViewingJig',
    ActionTrigger = 'ActionTriggered',
  }
}
