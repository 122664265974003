
import JigDateTimeFields from '@/components/input/JigDateTimeFields.vue'
import JigTextField from '@/components/input/JigTextField.vue'
import JigTextarea from '@/components/input/JigTextarea.vue'
import ExpansionPanels from '@/components/utils/ExpansionPanels.vue'
import { VersionConstants } from '@/store/modules/version/constants'
import { Platforms, Version } from '@/store/modules/version/types'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  components: {
    'expansion-panels': ExpansionPanels,
    'jig-text-field': JigTextField,
    'jig-textarea': JigTextarea,
    'jig-date-time-fields': JigDateTimeFields,
  },
})
export default class VersionEntryVue extends Vue {
  @Prop({ type: String, default: '' })
  public currentVersionName!: string

  private defaultPlatform: VersionConstants.VersionDefaults = VersionConstants.VersionDefaults.DefaultPlatform
  private isFormValid: boolean = false
  private enableEdit: boolean = false
  private isSubmitting: boolean = false
  private newVersion: Version = { ...VersionConstants.VersionEntry }
  private ExpiryReminderStartOffsetHRS: string = VersionConstants.VersionEntry.ExpiryReminderStartOffsetHRS.toString()
  private UpgradeCheckFrequencyHRS: string = VersionConstants.VersionEntry.UpgradeCheckFrequencyHRS.toString()
  private platforms: Platforms = VersionConstants.PlatformsOptions
  private platform: VersionConstants.VersionDefaults = VersionConstants.VersionDefaults.DefaultPlatform
  private applicationItems: Array<VersionConstants.VersionDefaults> = VersionConstants.ApplicationItems // At the moment we hard code to just 1 `jigspace` in list, in future we can extend it to display all available applications.

  public $refs!: Vue['$refs'] & {
    supportStartDateTime: JigDateTimeFields
    versionEntryForm: any
  }

  protected mounted() {
    this.calculateNextVersionName()
  }

  public HandleSubmissionResults(success: boolean) {
    if (success) {
      this.resetForm()
    } else {
      this.isSubmitting = false
    }
  }

  private calculateNextVersionName() {
    if (!this.currentVersionName) {
      return
    }

    // Get the 2nd digit of version name: '2' of 1.2.0.0
    const seconNumIndex = 2
    const versionPart1 = this.currentVersionName.substring(0, seconNumIndex)
    const currentVerionNum = parseInt(this.currentVersionName.substring(seconNumIndex, seconNumIndex + 1), 10)
    const versionPart2 = this.currentVersionName.substring(seconNumIndex + 1)

    // As version name returned from API only has 3 places, manually append 4th place.
    this.newVersion.Name = `${versionPart1}${currentVerionNum + 1}${versionPart2}.0`
  }

  private numberRule(value: any) {
    const newValue = parseInt(value)

    if (newValue && !isNaN(newValue) && newValue >= 0) {
      return true
    }

    return 'This value should be a positive number'
  }

  private versionNameRule(value: any) {
    // The version name must always have 4 parts, the 4th digit is for build.
    const versionNameRegex = new RegExp(/^(([0-9]+\.){3})[0-9]+$/)

    if (!!value && versionNameRegex.test(value)) {
      return true
    }

    return 'Please fill a in valid version: X.X.X.X'
  }

  private closeEntryForm() {
    this.$emit('on-entry-form-close')
  }

  private resetDefaultValues() {
    this.ExpiryReminderStartOffsetHRS = VersionConstants.VersionEntry.ExpiryReminderStartOffsetHRS.toString()
    this.UpgradeCheckFrequencyHRS = VersionConstants.VersionEntry.UpgradeCheckFrequencyHRS.toString()

    this.newVersion.Application = VersionConstants.VersionEntry.Application
    this.newVersion.SupportEnd = VersionConstants.VersionEntry.SupportEnd
    this.newVersion.ExpiryReminderStartOffsetHRS = VersionConstants.VersionEntry.ExpiryReminderStartOffsetHRS
    this.newVersion.UpgradeCheckFrequencyHRS = VersionConstants.VersionEntry.UpgradeCheckFrequencyHRS
    this.newVersion.UpgradeReminderMessage = VersionConstants.VersionEntry.UpgradeReminderMessage
  }

  private updateExpiryReminderStartOffsetHRS() {
    const newValue = parseInt(this.ExpiryReminderStartOffsetHRS, 10)

    if (!isNaN(newValue)) {
      this.newVersion.ExpiryReminderStartOffsetHRS = newValue
    }
  }

  private updateUpgradeCheckFrequencyHRS() {
    const newValue = parseInt(this.UpgradeCheckFrequencyHRS, 10)

    if (!isNaN(newValue)) {
      this.newVersion.UpgradeCheckFrequencyHRS = newValue
    }
  }

  private updatePlatform() {
    this.newVersion.Platform = this.platform
  }

  private submitNewEntry() {
    this.isSubmitting = true
    this.$refs.versionEntryForm.validate()

    this.$emit('on-submit-entry', this.newVersion)
  }

  private resetForm() {
    this.isSubmitting = false
    this.newVersion = {
      ...this.newVersion,
      ...VersionConstants.VersionEntry,
    }
    this.platform = this.defaultPlatform
    // Reset Support Start otherwise it will be cleared as empty.
    this.$refs.supportStartDateTime.UpdateDateTime()
    this.$refs.versionEntryForm.resetValidation()

    this.$emit('on-reset-entry-form', true)
  }

  private updateSupportStart(payload: string) {
    this.newVersion.SupportStart = payload
  }
}
