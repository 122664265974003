import { TenantHelpers } from '@/utils/tenant-helpers'
import { SubscriptionConst } from './subscription'

export namespace TenantConst {
  export enum auth0ProfileKeys {
    uid = 'https://jig.space/jiguserid',
    tenants = 'https://jig.space/jigtenants',
  }

  export enum defaultTenantsPagination {
    page = 1,
    limit = 100,
    name = '',
  }

  export const defaultTenant = {
    Active: false,
    ID: TenantHelpers.InvalidTenantID,
    Name: 'Team Name',
    UserCount: 0,
    CreatedAt: new Date(),
    ColorHex1: TenantHelpers.TenantDefaultColor1Hex,
    ColorHex2: TenantHelpers.TenantDefaultColor2Hex,
    Logourl: '',
    LogoImage: '',
    Subscription: SubscriptionConst.defaultSubscription,
    JigFinishedCustomLink: '',
    LogoUpdated: false,
  }

  export const defaultUserAttribtes = {
    SignupFirstName: '',
    SignupLastName: '',
    SignupType: '',
    SignupRole: '',
    SignupUsecase: '',
    SignupOrganisationName: '',
  }

  export const defaultTenantUser = {
    ID: TenantHelpers.InvalidTenantUserID,
    Uid: 0,
    dateCreated: new Date(),
    email: '',
    name: '',
    roles: [],
    status: '',
    tenantId: TenantHelpers.InvalidTenantID,
    user_attributes_json: defaultUserAttribtes,
    username: '',
    permissions: {},
    tenantAdminRoles: [],
    extraPermissionNames: [],
  }
}
