
import ExpansionPanels from '@/components/utils/ExpansionPanels.vue'
import { VersionConstants } from '@/store/modules/version/constants'
import { Namespace, StandardObject } from '@/store/types'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { State } from 'vuex-class'

@Component({
  components: {
    'expansion-panels': ExpansionPanels,
  },
})
export default class VersionDetailsVue extends Vue {
  @Prop({ type: Boolean, default: true })
  public isNewEntryOpen!: boolean
  @Prop({ type: Boolean, default: true })
  public isSuperUser!: boolean
  @Prop({ type: Object, default: () => ({}) })
  public platformOptions!: StandardObject
  @Prop({ type: Array, default: () => [] })
  public availableApplications!: any[]
  @Prop({ type: String, default: VersionConstants.VersionDefaults.DefaultPlatform })
  public defaultPlatform!: string
  @Prop({ type: String, default: VersionConstants.VersionDefaults.DefaultApplication })
  public defaultApplication!: string

  @State('latestVersion', { namespace: Namespace.Version })
  public latestVersion!: StandardObject

  private platform: string = ''
  private latestVersionApplication: string = ''
  private apiDomain: string = process.env.VUE_APP_API_DOMAIN || ''

  @Watch('defaultPlatform', { immediate: true })
  onDefaultPlatformChanged(val: string) {
    if (this.platform !== val) {
      this.platform = val
    }
  }

  @Watch('defaultApplication', { immediate: true })
  onDefaultApplicationChanged(val: string) {
    if (this.latestVersionApplication !== val) {
      this.latestVersionApplication = val
    }
  }

  private get version(): StandardObject {
    const currentVersion = this.latestVersion[this.latestVersionApplication]

    return currentVersion || {}
  }

  private getSupportDateString(dateString: string) {
    const localDate = new Date(dateString).toLocaleString()
    // toString() returns date time string in following formt:
    // "Tue Aug 03 2021 11:26:18 GMT+1000 (Australian Eastern Standard Time)"
    // Split using whitespace will get:
    // ["Tue", "Aug", "03", "2021", "11:27:12", "GMT+1000", "(Australian", "Eastern", "Standard", "Time)"]
    const gmtArray = new Date(dateString).toString().split(/\s/g)

    return `${localDate}, ${gmtArray[5]}<br>(ISO String: ${dateString})`
  }

  private updateVersionPlatform() {
    this.$emit('on-version-platform-change', {
      Platform: this.platform,
    })
  }

  private openEntryForm() {
    this.$emit('on-open-entry-form')
  }

  private openAppDeeplinkDownloadUrlUpdateForm() {
    this.$emit('on-open-app-deeplink-updater-form')
  }

  private updateApplication() {
    // In future when we are able to get full list of available applications, on select change we will able to retrieve new Latest Version details.
    this.$emit('on-version-update', {
      Application: this.latestVersionApplication,
    })
  }
}
