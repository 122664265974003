import { VersionConstants } from '@/store/modules/version/constants'

export namespace AppConst {
  export const appName = 'dashboard'
  export const apiDomain = (process.env.VUE_APP_API_DOMAIN as string).replace(/\/$/, '') // Remove trailing slashes from the audience
  // export const apiDomain = 'http://localhost:4443'

  export enum APIEndpoint {
    myJigsV2Url = '/v2/me/jigs',
    jigsV1Url = '/jigs/v1',
    jigsV2Url = '/jigs/v2',
    meJigsV2Url = '/me/jigs/v2',
    jigMetadataV1 = 'metadata/v1',
    myJigsV2UrlMetrics = '/v2/me/jigs/metrics',
    publicModelsUrl = '/library',
    myJigsUrl = '/me/jigs',
    libraryCategoriesUrl = '/library/categories',
    libraryCategoriesUrlStatesUrl = `/states`,
    tenantsV1Url = '/tenants/v1',
    JigsListingUrlV2 = '/jigs/v2',
    JigsListingUrl = '/jigs/v3',
    JigsListingUrlImagesV1Url = `/images/v1`,
    usersV1Url = '/users/v1',
    profileV1Url = '/profiles/v1',
    statisticsUrl = '/statistics/reports',
    billingUrl = '/billing',
    billingCheckoutUrl = `/stripe/create-checkout-session`,
    tenantSetupJobsUrl = '/tenant-setup-jobs/v1',
    completedTenantSetupJobsUrl = '/secure-tenant-setup-jobs/v1/completed',
    versionUrl = '/version/latest',
    hubSpotVisitorIdTokenUrl = '/hs/v1/visitoridtoken',
    createNewVersionUrl = '/version',
    getSmallQRCodeUrl = '/qrcode/v1/small/v1',
    getLargeQRCodeUrl = '/qrcode/v1/large/v1',
    createStripeCheckoutUrl = '/billing/stripe/create-checkout-session',
    createStripeCustomerPortalUrl = '/billing/stripe/create-customer-portal-session',
    branchIODownloadUrlsV1 = '/branchio/link-download-urls/v1',
    dynaconfConfig = '/configs/v1',
  }

  export const libraryCategoryStatesUrl = `${APIEndpoint.libraryCategoriesUrl}${APIEndpoint.libraryCategoriesUrlStatesUrl}`
  export const tenantsImagesV1Url = `${APIEndpoint.tenantsV1Url}${APIEndpoint.JigsListingUrlImagesV1Url}`
  export const checkoutUrl = `${APIEndpoint.billingUrl}${APIEndpoint.billingCheckoutUrl}`
  export const communitySlackWebhook = process.env.VUE_APP_SLACK_WEBHOOK || ''
  export const hubspotId = process.env.VUE_APP_HUBSPOT_ID || ''

  export enum FormValidate {
    passwordMinLength = 8,
  }

  export enum Versions {
    versionErrorName = 'getting latest version details',
  }

  export enum HelpHero {
    helpHeroJigCountKey = 'jigCount',
    helpHeroModelsCountKey = 'modelsCount',
    helpHeroTenantUsersCountKey = 'tenantUsersCount',
    helpHeroTenantLogoUrlKey = 'tenantLogoUrl',
  }

  export enum Tracking {
    eventLabel = 'target-properties',
  }

  export enum AppDownloadURL {
    dev = 'https://link.jig.space/e/integration-test-dummy-link',
  }

  export enum AppVariant {
    MacOS = 'macos',
    Windows = 'win',
    Windows_Zip = 'win-zip',
  }

  export const appDownloadDeeplinks = {
    jigspace: [
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.MacOS,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.Windows,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
      {
        platform: VersionConstants.PlatformsOptions.desktop,
        variant: AppVariant.Windows_Zip,
        nonProdDeeplinkUrl: AppDownloadURL.dev,
      },
    ],
  }

  export const sauronAccount = 'sauron@jig.space'
}
