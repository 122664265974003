export namespace MueConst {
  export enum LockAction {
    createLock = 'lock',
    refreshLock = 'refresh-lock-expiry',
    releaseLock = 'release-lock',
    breakLock = 'break-lock',
    notifyAwaitingLock = 'notify-awaiting-lock',
  }

  export enum LockResponseCode {
    emailSent = 200,
    lockAlreadyExists = 423,
    lockInvalidForRelease = 400, // lock release: for invalid type or action or the user didn’t have the lock to begin with
    lockObtained = 201,
    lockRemoved = 200,
    lockUpdated = 200,
  }

  export enum MUENotifyEmailStatus {
    successful = 'success',
    alreadySentOnce = 'conflict',
    failed = 'failed',
    invalidJigID = 'invalid',
    invalidJigIDCode = 400,
  }

  export const nonSystemErrors = {
    conflict: {
      status: 409,
      copy: '',
    },
    unprocessableEntity: {
      status: 422,
      copy: `Oops, the Jig has been updated by another more recent session and your changes cannot be saved. Refresh to see the latest version.`,
    },
  }
}
