export namespace JigConst {
  export enum SharePermission {
    view = 'view',
    reshare = 'sharable-readonly',
    edit = 'edit',
  }

  export enum JigTenantsType {
    own = 'own',
    share = 'sharing',
  }

  export enum JigVisibilities {
    Public = 'public',
    Link = 'link',
    Private = 'private',
    Password = 'link-password',
  }

  export const jigVisibilitiesStaff = {
    [JigVisibilities.Public]: 'Public sharing',
    [JigVisibilities.Link]: 'Anyone with link can view',
    [JigVisibilities.Private]: 'Private (Invite only)',
    [JigVisibilities.Password]: 'Password protected',
  }

  export const jigVisibilities = {
    [JigVisibilities.Link]: 'Anyone with link can view',
    [JigVisibilities.Private]: 'Private (Invite only)',
    [JigVisibilities.Password]: 'Password protected',
  }

  export const teamRights = {
    [SharePermission.view]: 'View only',
    [SharePermission.reshare]: 'Team can re-share',
    [SharePermission.edit]: 'Team can edit',
  }

  export enum JigCopyable {
    Owner = 'owner_user',
    Anyone = 'anyone',
    Tenant = 'owner_tenant',
  }

  export const copyableByList = [
    { value: JigCopyable.Anyone, copy: 'Anyone can copy', disabled: false },
    { value: JigCopyable.Tenant, copy: 'Team only', disabled: true },
  ]

  export const jigPermissionViewerErrors = {
    single: 'Only the owner and Team managers can change the permissions of this Jig',
    multiple: 'Only the owner and Team managers can change the permissions of these Jigs',
  }

  export enum jigShareModalMode {
    default = 'default',
    manageUser = 'manage-user',
  }

  export enum jigsListSortTerm {
    asc = 'asc',
    desc = 'desc',
  }

  export const jigEvents = {
    onUpdatingJigVisibility: 'on-updating-jig-visibility',
    onUpdateJigShareConfig: 'on-update-jig-share-config',
    onJigTransfer: 'on-jig-transfer',
    onJigDelete: 'on-jig-delete',
    onJigDuplicate: 'on-jig-duplicate',
    onJigSharelinkCopy: 'on-jig-sharelink-copy',
    onJigOwnershipChanged: 'on-jig-ownership-changed',
    onJigOwnershipFailed: 'on-jig-ownership-failed',
  }

  export const jigPermissionEvents = {
    onPermissionUpdateStart: 'on-permission-update-start',
    onPermissionUpdateError: 'on-permission-update-error',
    onPermissionUpdateCallback: 'on-permission-update-callback',
    onDeletePermissionSuccessful: 'on-delete-permission-successful',
    onInviteFailed: 'on-invite-failed',
    onInviteSuccessful: 'on-invite-successful',
    onBulkInviteSuccessful: 'on-bulk-invite-successful',
    onEditorUpdateSuccessful: 'on-editor-update-successful',
  }

  export const jigShareEvents = {
    onPermissionTabMounted: 'on-permission-tab-mounted',
    onPermissionTabUpdated: 'on-permission-tab-updated',
    onAdvancedPermissionTriggered: 'on-advanced-permission-triggered',
  }

  // For root level events hannled by eventBus.
  // We should separate it out from native vue $emit to avoid possible double handling.
  export const rootJigEvents = {
    onJigTransfer: `root-${jigEvents.onJigTransfer}`,
    onJigDelete: `root-${jigEvents.onJigDelete}`,
    showJigPermissionPrompt: 'root-show-jig-permission-prompt',
    showCopyJigPrompt: 'root-show-copy-jig-prompt',
    onCopyShareableLink: 'root-on-copy-shareablelink',
    onLockNotifyRequired: 'root-on-lock-notify-required',
  }

  export enum JigListQueryFilters {
    ownerUserId = 'owner_user_id',
    sharedWithUser = 'shared_with_user',
    tenantOwner = 'tenant_owner',
    tenantSharing = 'tenant_sharing',
  }

  export enum JigDBFields {
    dateUpdated = 'p.date_updated',
    name = 'p.name',
    views = 'p.views',
    visibility = 'p.visibility',
    uid = 'p.uid',
  }
}
