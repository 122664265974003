export namespace UtilsConst {
  export const invalidIndex = -1
  export const invalidNumber = -1

  // Match all non-printable characters but except '\n', '\r', '\t', '\b', '\f' characters.
  export const nonPrintableCopyRegex = new RegExp(/(?:(?![\n\r\t\b\f])[\p{Cc}]+|\\u([0-9a-fA-F]{4}))/gu)

  export enum RequestHeaders {
    CustomHeaderKeyAppName = 'X-Jig-App',
    CustomHeaderKeyTenantId = 'X-Jig-Tenant-Id',
  }

  export enum HTTPAction {
    GET,
    POST,
    PUT,
    DELETE,
  }

  export enum AvatarColor {
    dune_100 = 'dune-100',
    rain_700 = 'rain-700',
    info_500 = 'info-500',
    info_300 = 'info-300',
  }

  export const userAvatarColors = [AvatarColor.dune_100, AvatarColor.rain_700, AvatarColor.info_500, AvatarColor.info_300]

  export const searchEvents = {
    onSearch: 'on-search',
    onSearchReset: 'on-search-reset',
  }

  export const defaultUsersJigsPerPage: number = 250
  export const defaultJigStaffJigsPerPage: number = 500
}
