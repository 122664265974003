export namespace SubscriptionConst {
  export enum Subscription {
    subscriptionTierFree = 'free',
    subscriptionTierTeam = 'team',
    subscriptionTierOrganization = 'organization',
    ManagedByTypeTeamManager = 'team_manager',
    ManagedByTypeJigStaff = 'jig_staff',
    pricingSet_2020_01 = 'set_2020_01',
    pricingSet_2022_07 = 'set_2022_07',
    pricingSet_2023_02 = 'set_2023_02',
    pricingSet_2023_06 = 'set_2023_06',
    defaultSeatsIncludedInTier = '-',
    defaultMaxJigsInTier = 0,
    defaultMaxJigsPerInTier = '-',
    embedQueryParamDefaultLang = 'en-US',
  }

  export const subscriptionTiers: Subscription[] = [
    Subscription.subscriptionTierFree,
    Subscription.subscriptionTierTeam,
    Subscription.subscriptionTierOrganization,
  ]

  export const subscriptionManagedByTypes: {
    value: Subscription
    text: string
  }[] = [
    {
      value: Subscription.ManagedByTypeTeamManager,
      text: 'Team manager',
    },
    {
      value: Subscription.ManagedByTypeJigStaff,
      text: 'Jig staff',
    },
  ]

  export const pricingSets: Subscription[] = [
    Subscription.pricingSet_2020_01,
    Subscription.pricingSet_2022_07,
    Subscription.pricingSet_2023_02,
    Subscription.pricingSet_2023_06,
  ]

  export enum Features {
    AVPFullAccessFeatureGate = 'ViewingGate_MAR_2024',
    AVPFeaturePullApartKey = 'AVP_Feature_PullApart',
  }

  export const AVPFullAccessFeatureGate = 'ViewingGate_MAR_2024'
  export const AVPFeaturePullApartKey = 'AVP_Feature_PullApart'
  export const defaultSubscription = {
    AdditionalSeats: 0,
    AllowCustomBranding: false,
    AllowMovoJigs: false,
    AllowJigStats: false,
    AllowPasswordJigs: false,
    AllowPrivateJigs: false,
    AllowWorkshopPropertyPanel: false,
    ManagedByType: '',
    MaxJigs: -1,
    MaxJigsCountPer: '',
    ModelUploadMaxSizeMB: -1,
    OverLimitWarningDismissableUntil: '',
    OverLimitWarningShowContactUs: false,
    PricingSet: SubscriptionConst.Subscription.pricingSet_2023_06,
    SeatsIncludedInTier: -1,
    StripeCustomerId: '',
    StripeSubscriptionId: '',
    SubscriptionFeatures: {
      avp: { enabled: false },
      movo: { enabled: false },
      stats: {
        jigStats: { enabled: false },
      },
    },
    TeamStorageLimitMB: -1,
    TierType: '',
    TierTypeDisplayName: '',
    ViewJigStepMax: -1,
    RestrictedJigCopyableByTypes: [],
  }
}
