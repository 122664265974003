export namespace PermissionConst {
  export enum PermissionsResourceType {
    Jig = 0,
    User = 2,
  }

  export enum PermissionsAudienceType {
    User = 0,
  }
}
